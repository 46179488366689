.Profile {
    display: flex;
    flex-direction: column;
}

.Profile>div {
    display: flex;
    flex-direction: row;
}

.Profile .profile-view {
    margin: 36px 24px;
}

.Profile .input {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
}

.loading-circle {
    margin-right: 2px;
}

.Profile .save-btn {
    margin-left: auto;
    margin-top: 15px;
}

.Profile .profile-view img {
    border-radius: 50px;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.Profile .profile-view .input-section .pic-upload .ant-upload-list{
    display: none;
}

.Profile .profile-view .cameraIcon {
    color: #3E3D4B;
    font-size: 18px;

}

.Profile .profile-view .ant-upload img {
    height: 100%;
}
