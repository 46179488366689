.dashboard .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.dashboard .header .menu-section{
  padding: 40px;
}

.dashboard .logo {
  height: 32px;
  margin: 16px;
}

.site-layout .content{
  background: #fff;
}

.site-layout .header{
  background: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.site-layout .header .avatar {
  margin: 0 24px;
}