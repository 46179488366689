.ChangePassword {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.ChangePassword > div {
  display: flex;
  flex-direction: row;
}

.ChangePassword .input {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
}

.ChangePassword .save-btn {
  margin-left: auto;
  margin-top: 16px;
}
