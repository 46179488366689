.oAuthSelectWorkspace {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oAuthSelectWorkspace .logo {
    width: 67px;
    height: 67px;
    margin-top: 113px;
    margin-bottom: 32px;
}

.oAuthSelectWorkspace h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    color: #272727;
    margin-bottom: 72px;
    text-align: center;
}

.oAuthSelectWorkspace .workspaceBox {
    background: #EDE5FB;
    border-radius: 4px;
    width: 321px;
    height: 217px;
    padding: 16px;
    cursor: pointer;
    border: 3px solid #EDE5FB;
}

.oAuthSelectWorkspace .workspaceBox:hover {
    border: 3px solid #650DF2;
    box-shadow: 0px 15px 25px #F0E8FF;
}

.oAuthSelectWorkspace .workspaceBox .title p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
    padding-left: 0;
    margin-bottom: 4px;
    color: #64748B;
    text-align: left;
}

.oAuthSelectWorkspace .workspaceBox .title h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #191D23;
    margin-bottom: 16px;
    text-align: left;
}

.oAuthSelectWorkspace .workspaceBox .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #191D23;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: clip;
}

.oAuthSelectWorkspace .workspaceOption {
    display: flex;
    justify-content: center;
    gap: 119px;
    flex-wrap: wrap;
}
