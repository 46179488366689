.orgSignUp {
  margin: 48px 120px;
}

.orgSignUp .logo {
  height: 67px;
  width: 67px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: 149px;
}

.orgSignUp .signUp-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.orgSignUp .form-section {
  max-width: 482px;
}

.orgSignUp .form-section .title-section h2 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 120%;
  color: #272727;
  text-align: center;
  margin: 0;
}

.orgSignUp .form-section .title-section p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: #272727;
  text-align: center;
  margin: 0;
  margin-bottom: 16px;
}


.orgSignUp .signUp-container .side-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orgSignUp .signUp-container .input-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.orgSignUp .signUp-container input {
  border: 1px solid #3E3D4B;
  text-align: left;
  border-radius: 5px;
  padding: 0px 16px;
  height: 50px;
}

.orgSignUp .signUp-container .input-field label {
  color: #272727;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: 16px;
}

.orgSignUp .signUp-container .text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: rgba(39, 39, 39, 0.54);
  margin-top: 4px;
  margin-bottom: 32px;
}

.orgSignUp .signUp-container button{
  height: 52px;
  width: 100%;
  background: #650DF2;
  border-radius: 5px;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 140%;
color: white;
border: none;
cursor: pointer;
}

.orgSignUp .signUp-container button:hover{
  background: #8243F5;
border-radius: 4px;
}

.orgSignUp .signUp-container .donot-have-account {
  text-align: center;
  color: rgba(39, 39, 39, 0.54);
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  margin-top: 16px;
  margin-bottom: 48px;
}

.orgSignUp .btn-loading{
  margin-right: 4px;
}

.orgSignUp .donot-have-account a {
  font-weight: 700;
  color: #650DF2;
  font-family: 'DM Sans';
  font-size: 12px;
  line-height: 130%;
}


@media screen and (max-width: 1120px) {
  .orgSignUp {
    background-color: white;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .orgSignUp .logo {
    margin: 48px 120px;
  }

  .orgSignUp .signUp-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orgSignUp .form-section {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .orgSignUp .signUp-container .side-image {
    display: none;
  }
}

@media screen and (max-height: 900px) {
  .orgSignUp .logo {
    margin-bottom: 0px;
  }
}
