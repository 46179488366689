.orgOnboarding .profile-screen .profile-view .ant-upload-select {
    height: 96px;
    width: 96px;
    border: 2px solid rgba(39, 39, 39, 0.54);
    margin: 0;
    padding: 0;
    border-radius: 50px;
    background-color: #ffffff !important;
}

.orgOnboarding .profile-screen img {
    border-radius: 50px;
}

.orgOnboarding .profile-screen .input-section .pic-upload .ant-upload-list{
    display: none;
}

.orgOnboarding .profile-screen .cameraIcon {
    color: #3E3D4B;
    font-size: 18px;
}

.orgOnboarding .profile-view .ant-upload img {
    height: 100%;
}
