body {
    height: 100vh !important;
    position: relative;
    margin: 0px;
    font-family: 'Inter';font-size: 22px;
}

.App {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    position: relative;
}