.orgAfterSignUp {
    max-width: 530px;
    padding: 48px;
    box-shadow: 0px 20px 40px rgba(105, 97, 88, 0.12);
    border-radius: 8px;
    align-items: center;
    margin: 150px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.orgAfterSignUp .logo {
    height: 67px;
    width: 67px;
    text-align: center;
    margin-bottom: 34px;
}

.orgAfterSignUp h2 {
    margin: 0;
    text-align: left;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #272727;
}

.orgAfterSignUp .info p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #272727;
    margin-top: 6px;
    margin-bottom: 32px;
}

.orgAfterSignUp button {
    height: 52px;
    width: 100%;
    background: #650DF2;
    border-radius: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 140%;
    color: white;
    border: none;
    cursor: pointer;
}
