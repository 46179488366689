.OrgOnboarding {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OrgOnboarding .steps-content {
  text-align: center;
}

.OrgOnboarding .steps-action {
  margin-right: 60px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.OrgOnboarding .welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OrgOnboarding .welcome-icon {
  width: 64px;
  height: 64px;
  margin-top: 40px;
}

.OrgOnboarding .welcome-screen .info {
  margin-top: 26px;
  margin-bottom: 48px;
}
.OrgOnboarding .welcome-screen .welcome-image {
  height: 299px;
  margin-bottom: 48px;
}

.OrgOnboarding p {
  font-size: 17px;
  font-family: "DM Sans";
  font-weight: 400;
  line-height: 23.8px;
  color: #272727;
  margin: 0;
}

.OrgOnboarding .profile-screen {
  margin-top: 97px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70vw;
}

.OrgOnboarding .side-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 456px;
  max-width: 413px;
}

.OrgOnboarding .pic-upload .image-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OrgOnboarding .profile-screen a {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  color: #650df2;
}

.OrgOnboarding .profile-screen .input-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 482px;
  padding: 24px 30px;
  border: 2px solid #d9d9d9;
  border-radius: 16px;
}

.OrgOnboarding .profile-screen .img-section {
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrgOnboarding .profile-screen .input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.OrgOnboarding .profile-screen .pic-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;
  margin-top: 32px;
}
.OrgOnboarding .profile-screen .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.OrgOnboarding .profile-screen .input .title {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  margin-bottom: 4px;
}

.OrgOnboarding button {
  background: #650df2;
  border-radius: 5px;
  padding: 12px 16px;
  height: 52px;
  color: #ffffff;
  border: none;
  font-size: 17px;
  font-family: "DM Sans";
  cursor: pointer;
}

.OrgOnboarding button:hover {
  background: #8243f5;
  border-radius: 4px;
}
.welcome-screen-btn {
  width: 328px;
}
.profile-screen-btn {
  width: 100%;
}
.one-row-btns {
  display: flex;
  gap: 16px;
  width: 100%;
}
.OrgOnboarding .profile-screen img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1000px) {
  .OrgOnboarding .side-image {
    display: none;
  }
}
