.Onboarding {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Onboarding .steps-content {
  text-align: center;
}

.Onboarding .steps-action {
  margin-right: 60px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.Onboarding .welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Onboarding .welcome-icon{
  width: 64px;
  height: 64px;
  margin-top: 40px;
}

.Onboarding .welcome-screen .info {
  margin-top: 26px;
  margin-bottom: 48px;
}
.Onboarding .welcome-screen .welcome-image {
  height: 299px;
  margin-bottom: 48px;
}

.Onboarding p {
  font-size: 17px;
  font-family: 'DM Sans';
  font-weight: 400;
  line-height: 23.8px;
  color: #272727;
  margin: 0;
}

.Onboarding .profile-screen{
  margin-top: 97px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70vw;
}

.Onboarding .side-image{
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 456px;
  max-width: 413px;
}

.Onboarding .pic-upload .image-upload{
 display: flex;
  align-items: center;
  justify-content: space-between
}

.Onboarding .profile-screen a{
font-family: 'DM Sans';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 140%;
color: #650DF2;
}
.Onboarding .profile-screen .input-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 482px;
  padding: 24px 30px;
  border: 2px solid  #d9d9d9;
  border-radius: 16px;
}

.Onboarding .profile-screen .img-section {
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Onboarding .profile-screen .input-group{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Onboarding .profile-screen .pic-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;
  margin-top: 32px;
}
.Onboarding .profile-screen .input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.Onboarding .profile-screen .input .title {
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  margin-bottom: 4px;
}

.Onboarding button{
  background: #650DF2;
  border-radius: 5px;
  padding: 12px 16px;
  height: 52px;
  color: #ffffff;
  border: none;
  font-size: 17px;
  font-family: 'DM Sans';
  cursor: pointer;
}

.Onboarding button:hover{
  background: #8243F5;
  border-radius: 4px;
}
 .welcome-screen-btn{
  width: 328px;
 }
 .profile-screen-btn{
  width: 100%;
 }
 .one-row-btns{
  display: flex;
  gap: 16px;
  width: 100%;
 }
.Onboarding .profile-screen img{
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1000px) {
  .Onboarding .side-image{
    display: none;
  }
} 

