.forgot-password {
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 48px;
    margin-bottom: 0;
}

.forgot-password .logo {
    height: 67px;
    width: 67px;
    margin-bottom: 190px;
}

.side-image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-password-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.forgot-password-container img {
    max-width: 456px;
}

.forgot-password-container .reset-pwd-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 482px;
}

.forgot-password-container .reset-pwd-info h2 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    color: #272727;
    margin: 0;
}

.forgot-password-container .reset-pwd-info p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: #272727;
    margin-bottom: 32px;
}

.forgot-password-container .reset-pwd-info .reset-pwd-input {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reset-pwd-input label {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #272727;
    margin-bottom: 4px;
}

.reset-pwd-btn {
    height: 52px;
    background: #650DF2;
    border-radius: 5px;
    width: 100%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 140%;
    color: #FFFFFF;
    border: none;
    margin-bottom: 16px;
    cursor: pointer;
    margin-top: 22px;
}

.reset-pwd-btn:hover {
    background: #8243F5;
    border-radius: 4px;
}

.reset-pwd-info a {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: rgba(39, 39, 39, 0.54);
}

@media screen and (max-width: 1120px) {
    body{
        background-color: white;
    }
    .forgot-password {
        background-color: white;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .forgot-password .logo{
      margin: 48px 120px;
    }
    .forgot-password-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .forgot-password-container .reset-pwd-info {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
    }
    
   .side-image {
      display: none;
    }
  }
