.UpdateOrganization {
    display: flex;
    flex-direction: column;
}

.UpdateOrganization>div {
    display: flex;
    flex-direction: row;
}

.UpdateOrganization .profile-view {
    margin: 36px 24px;
}

.UpdateOrganization .input {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
}

.loading-circle {
    margin-right: 2px;
}

.UpdateOrganization .save-btn {
    margin-left: auto;
    margin-top: 15px;
}

.UpdateOrganization .profile-view .ant-upload-select {
    height: 96px;
    width: 96px;
    border: 2px solid rgba(39, 39, 39, 0.54);
    margin: 0;
    padding: 0;
    border-radius: 50px;
    background-color: #ffffff !important;
}

.UpdateOrganization .profile-view img {
    border-radius: 50px;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.UpdateOrganization .profile-view .input-section .pic-upload .ant-upload-list{
    display: none;
}

.UpdateOrganization .profile-view .cameraIcon {
    color: #3E3D4B;
    font-size: 18px;

}

.UpdateOrganization .profile-view .ant-upload img {
    height: 100%;
}
