.forgotPasswordEmail {
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(105, 97, 88, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 200px;
    max-width: 500px;
    padding: 20px 30px;
}

.forgotPasswordEmail img {
    height: 67px;
    width: 67px;
    margin-bottom: 32px;
}

.forgotPasswordEmail h2 {
    color: #272727;
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 35px;
    line-height: 1.4;
    margin: 0;
    margin-bottom: 30px;
    font-style: normal;
    text-align: center;
}

.forgotPasswordEmail p {
    font-family: 'DM Sans';
    font-weight: 400;
    margin: 0;
    font-size: 20px;
    line-height: 130%;
    padding-left: 5px;
    text-align: center;
}
.forgotPasswordEmail button{
    box-sizing: border-box;
    width: 100%;
    margin-top: 32px;
    font-size: 17px;
    height: 52px;
    background-color: #650DF2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.forgotPasswordEmail button:hover{
    background: #8243F5;
    border-radius: 4px;
}

@media screen and (max-width: 600px) {
   body{
    background: #ffffff;
   }
   .forgotPasswordEmail{
    max-width: 100%;
   }
    
}
