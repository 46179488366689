:root {
  --col-sidebar: #fff;
}

.ant-menu > .ant-menu-item,
.ant-menu > .ant-menu-item:hover {
  color: black !important;
}

.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-item-selected:hover {
  color: #fff !important;
}
