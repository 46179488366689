.User .search {
  width: 300px;
}

.User .header {
  display: flex;
  justify-content: space-between;
}

.User .filter {
    margin: 16px 0;
}
